/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TextComponent from "../../../components/Form/Text";
import top from "../../../services/top";
import { DataTable } from "../../../components/HOC's/DataTableTop";
import { DataTable as DataTableSin } from "../../../components/HOC's/DataTableTopSinClick";
import { createColumnHelper } from "@tanstack/react-table";
import { Columns, ColumnsZonas } from "../../../utils/Top";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function Top({ getProductosZona }: any) {
  const [form] = useState({
    // primer día del mes actual
    desde: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      .toISOString()
      .split("T")[0],
    // último día del mes actual
    hasta: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      .toISOString()
      .split("T")[0],
  });
  const [columns, setcolumns] = useState<any>([]);
  const [columns2, setcolumns2] = useState<any>([]);
  const [data, setdata] = useState<any>([]);
  const [data2, setdata2] = useState<any>([]);
  const [loader, setloader] = useState(false);

  const ExportExcel = (array: any) => {
    const newArray = array.map((res: any) => {
      delete res.created_at;
      delete res.updated_at;
      delete res.id_user_zoho;
      return res;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(newArray);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Top-venta" + fileExtension);
  };

  const ExportExcel2 = (array: any) => {
    const newArray = array.map((res: any) => {
      delete res.created_at;
      delete res.updated_at;
      delete res.id_user_zoho;
      return res;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(newArray);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Ventas-Zona" + fileExtension);
  };

  useEffect(() => {
    top
      .Top10Get({
        desde: form.desde,
        hasta: form.hasta,
      })
      .then((response: any) => {
        createArrayListadoProductos(response);
        setloader(false);
      });
    top.ventasZonasGet().then((response: any) => {
      createArrayListadoZonas(response.zonas);
    });
  }, []);

  const createArrayListadoProductos = (response: any) => {
    const columnHelper = createColumnHelper<any>();
    const columns = Columns().map(({ title, campo }: any) => {
      return columnHelper.accessor(campo, {
        cell: (info: any) => info.getValue(),
        header: title ?? "",
      });
    });
    setcolumns(columns);
    const data = response;
    const newData = data.map((res: any, index: any) => {
      return {
        _id: (index + 1).toString(),
        modelo: res.modelo,
        cantidad: res.cantidad.toString(),
      };
    });
    setdata(newData);
  };

  const createArrayListadoZonas = (response: any) => {
    const columnHelper = createColumnHelper<any>();
    const columns = ColumnsZonas().map(({ title, campo }: any) => {
      return columnHelper.accessor(campo, {
        cell: (info: any) => info.getValue(),
        header: title ?? "",
      });
    });
    setcolumns2(columns);
    const datas = response;
    const newData = datas.map((res: any, index: any) => {
      return {
        nombre: res.nombre,
        total: parseMoney(res.total),
        correo: res.correo,
      };
    });

    // ordenar por total
    newData.sort((a: any, b: any) => {
      const totalA = a.total.replace("$", "").replace(",", "");
      const totalB = b.total.replace("$", "").replace(",", "");
      return parseInt(totalB) - parseInt(totalA);
    });
    setdata2(newData);
  };

  const parseMoney = (value: any) => {
    return new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    }).format(value);
  };

  return (
    <div>
      <Box bg={"white"} borderRadius={20}>
        <Box display={"flex"} justifyContent={"center"}>
          <TextComponent
            color={"#f06e26"}
            fontSize={"xl"}
            fontWeight={"bolder"}
            textAlign={"center"}
            text="Top ventas"
            textTransform={"uppercase"}
          />
        </Box>
        <Box overflowX="auto">
          {data.length > 0 && (
            <DataTableSin
              columns={columns}
              data={data}
              edit=""
              onOpenUpdate={() => {}}
              setArray={[]}
              ExportExcel={ExportExcel}
            />
          )}
        </Box>
      </Box>

      <Box bg={"white"} borderRadius={20} mt={10}>
        <Box display={"flex"} justifyContent={"center"}>
          <TextComponent
            color={"#f06e26"}
            fontSize={"xl"}
            fontWeight={"bolder"}
            textAlign={"center"}
            text="Ventas / Zonas"
            textTransform={"uppercase"}
          />
        </Box>
        <Box overflowX="auto">
          {data2.length > 0 && (
            <DataTable
              columns={columns2}
              data={data2}
              edit=""
              onOpenUpdate={() => {}}
              setArray={[]}
              ExportExcel={ExportExcel2}
              getProductosZona={getProductosZona}
            />
          )}
        </Box>
      </Box>
    </div>
  );
}
